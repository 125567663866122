

code, samp, samp kbd, tt {
    font-family: $monospace-font-family;
    font-size: 0.8em;
    color: $text-deemphasised;
    @media (prefers-color-scheme: dark) {
        color: $text-deemphasised-dark;
    }
}

pre {
    padding: 0.7em;
    overflow: auto;
    border-width: 1px;
    border-style: solid;
    background-color: $p8-white;
    @media (prefers-color-scheme: dark) {
        background-color: $p8-midnight;
    }
}


.full-source figure {
    position: relative;
    left: calc(50% - 45vw);
    width: 90vw;

    pre {
        // overflow-y: auto;
        max-height: 90vh;
    }
}

pre.highlight, code[data-lang] {
    tab-size: 2;

    //from minima
    .c     { color: #998; font-style: italic } // Comment
    .err   { color: #a61717; background-color: #e3d2d2 } // Error
    .k     { font-weight: bold } // Keyword
    .o     { font-weight: bold } // Operator
    .cm    { color: #998; font-style: italic } // Comment.Multiline
    .cp    { color: #999; font-weight: bold } // Comment.Preproc
    .c1    { color: #998; font-style: italic } // Comment.Single
    .cs    { color: #999; font-weight: bold; font-style: italic } // Comment.Special
    .gd    { color: #000; background-color: #fdd } // Generic.Deleted
    .gd .x { color: #000; background-color: #faa } // Generic.Deleted.Specific
    .ge    { font-style: italic } // Generic.Emph
    .gr    { color: #a00 } // Generic.Error
    .gh    { color: #999 } // Generic.Heading
    .gi    { color: #000; background-color: #dfd } // Generic.Inserted
    .gi .x { color: #000; background-color: #afa } // Generic.Inserted.Specific
    .go    { color: #888 } // Generic.Output
    .gp    { color: #555 } // Generic.Prompt
    .gs    { font-weight: bold } // Generic.Strong
    .gu    { color: #aaa } // Generic.Subheading
    .gt    { color: #a00 } // Generic.Traceback
    .kc    { font-weight: bold } // Keyword.Constant
    .kd    { font-weight: bold } // Keyword.Declaration
    .kp    { font-weight: bold } // Keyword.Pseudo
    .kr    { font-weight: bold } // Keyword.Reserved
    .kt    { color: #458; font-weight: bold } // Keyword.Type
    .m     { color: #099 } // Literal.Number
    .s     { color: #d14 } // Literal.String
    .na    { color: #008080 } // Name.Attribute
    .nb    { color: #0086B3 } // Name.Builtin
    .nc    { color: #458; font-weight: bold } // Name.Class
    .no    { color: #008080 } // Name.Constant
    .ni    { color: #800080 } // Name.Entity
    .ne    { color: #900; font-weight: bold } // Name.Exception
    .nf    { color: #900; font-weight: bold } // Name.Function
    .nn    { color: #555 } // Name.Namespace
    .nt    { color: #000080 } // Name.Tag
    .nv    { color: #008080 } // Name.Variable
    .ow    { font-weight: bold } // Operator.Word
    .w     { color: #bbb } // Text.Whitespace
    .mf    { color: #099 } // Literal.Number.Float
    .mh    { color: #099 } // Literal.Number.Hex
    .mi    { color: #099 } // Literal.Number.Integer
    .mo    { color: #099 } // Literal.Number.Oct
    .sb    { color: #d14 } // Literal.String.Backtick
    .sc    { color: #d14 } // Literal.String.Char
    .sd    { color: #d14 } // Literal.String.Doc
    .s2    { color: #d14 } // Literal.String.Double
    .se    { color: #d14 } // Literal.String.Escape
    .sh    { color: #d14 } // Literal.String.Heredoc
    .si    { color: #d14 } // Literal.String.Interpol
    .sx    { color: #d14 } // Literal.String.Other
    .sr    { color: #009926 } // Literal.String.Regex
    .s1    { color: #d14 } // Literal.String.Single
    .ss    { color: #990073 } // Literal.String.Symbol
    .bp    { color: #999 } // Name.Builtin.Pseudo
    .vc    { color: #008080 } // Name.Variable.Class
    .vg    { color: #008080 } // Name.Variable.Global
    .vi    { color: #008080 } // Name.Variable.Instance
    .il    { color: #099 } // Literal.Number.Integer.Long
    @media (prefers-color-scheme: dark) {
        .c     { color: #545454; font-style: italic } // Comment
        .err   { color: #f07178; background-color: #e3d2d2 } // Error
        .k     { color: #89DDFF; font-weight: bold } // Keyword
        .o     { font-weight: bold } // Operator
        .cm    { color: #545454; font-style: italic } // Comment.Multiline
        .cp    { color: #545454; font-weight: bold } // Comment.Preproc
        .c1    { color: #545454; font-style: italic } // Comment.Single
        .cs    { color: #545454; font-weight: bold; font-style: italic } // Comment.Special
        .gd    { color: #000; background-color: #fdd } // Generic.Deleted
        .gd .x { color: #000; background-color: #faa } // Generic.Deleted.Specific
        .ge    { font-style: italic } // Generic.Emph
        .gr    { color: #f07178 } // Generic.Error
        .gh    { color: #999 } // Generic.Heading
        .gi    { color: #000; background-color: #dfd } // Generic.Inserted
        .gi .x { color: #000; background-color: #afa } // Generic.Inserted.Specific
        .go    { color: #888 } // Generic.Output
        .gp    { color: #555 } // Generic.Prompt
        .gs    { font-weight: bold } // Generic.Strong
        .gu    { color: #aaa } // Generic.Subheading
        .gt    { color: #f07178 } // Generic.Traceback
        .kc    { font-weight: bold } // Keyword.Constant
        .kd    { font-weight: bold } // Keyword.Declaration
        .kp    { font-weight: bold } // Keyword.Pseudo
        .kr    { font-weight: bold } // Keyword.Reserved
        .kt    { color: #FFCB6B; font-weight: bold } // Keyword.Type
        .m     { color: #F78C6C } // Literal.Number
        .s     { color: #C3E88D } // Literal.String
        .na    { color: #008080 } // Name.Attribute
        .nb    { color: #EEFFFF } // Name.Builtin
        .nc    { color: #FFCB6B; font-weight: bold } // Name.Class
        .no    { color: #008080 } // Name.Constant
        .ni    { color: #800080 } // Name.Entity
        .ne    { color: #900; font-weight: bold } // Name.Exception
        .nf    { color: #82AAFF; font-weight: bold } // Name.Function
        .nn    { color: #555 } // Name.Namespace
        .nt    { color: #FFCB6B } // Name.Tag
        .nv    { color: #EEFFFF } // Name.Variable
        .ow    { font-weight: bold } // Operator.Word
        .w     { color: #EEFFFF } // Text.Whitespace
        .mf    { color: #F78C6C } // Literal.Number.Float
        .mh    { color: #F78C6C } // Literal.Number.Hex
        .mi    { color: #F78C6C } // Literal.Number.Integer
        .mo    { color: #F78C6C } // Literal.Number.Oct
        .sb    { color: #C3E88D } // Literal.String.Backtick
        .sc    { color: #C3E88D } // Literal.String.Char
        .sd    { color: #C3E88D } // Literal.String.Doc
        .s2    { color: #C3E88D } // Literal.String.Double
        .se    { color: #EEFFFF } // Literal.String.Escape
        .sh    { color: #C3E88D } // Literal.String.Heredoc
        .si    { color: #C3E88D } // Literal.String.Interpol
        .sx    { color: #C3E88D } // Literal.String.Other
        .sr    { color: #C3E88D } // Literal.String.Regex
        .s1    { color: #C3E88D } // Literal.String.Single
        .ss    { color: #C3E88D } // Literal.String.Symbol
        .bp    { color: #999 } // Name.Builtin.Pseudo
        .vc    { color: #FFCB6B } // Name.Variable.Class
        .vg    { color: #EEFFFF } // Name.Variable.Global
        .vi    { color: #EEFFFF } // Name.Variable.Instance
        .il    { color: #F78C6C } // Literal.Number.Integer.Long
    }
}



/* Pico-8-specific syntax highlighting */
$code-num:      $p8-sky;
$code-keyword:  $p8-pink;
$code-comment:  $p8-dusk;
$code-string:   $p8-orange;
$code-label:    $p8-black;
$code-function: $p8-lime;
$code-operator: $p8-black;
$code-constant: $p8-leather;

$dm-code-label: $p8-white;
$dm-code-operator: $p8-white;
$dm-code-constant: $p8-lemon;

code[data-lang="pico8"] {
    .c  { color: $code-comment; font-style: italic } // Comment
    .k  { color: $code-keyword; font-weight: bold } // Keyword
    .cm { color: $code-comment; font-style: italic } // Comment.Multiline
    .cp { color: $code-comment; font-weight: bold } // Comment.Preproc
    .c1 { color: $code-comment; font-style: italic } // Comment.Single
    .cs { color: $code-comment; font-weight: bold; font-style: italic } // Comment.Special
    .x  { color: $code-comment } // Other
    .m  { color: $code-num } // Literal.Number
    .s  { color: $code-string } // Literal.String
    .nf { color: $code-function; font-weight: bold } // Name.Function
    .ow { color: $code-keyword; font-weight: bold } // Operator.Word
    .nl { color: $code-label; font-weight: bold } // Name.Label
    .mf { color: $code-num } // Literal.Number.Float
    .mh { color: $code-num } // Literal.Number.Hex
    .mi { color: $code-num } // Literal.Number.Integer
    .mo { color: $code-num } // Literal.Number.Oct
    .sb { color: $code-string } // Literal.String.Backtick
    .sc { color: $code-string } // Literal.String.Char
    .sd { color: $code-string } // Literal.String.Doc
    .s2 { color: $code-string } // Literal.String.Double
    .sh { color: $code-string } // Literal.String.Heredoc
    .si { color: $code-string } // Literal.String.Interpol
    .sx { color: $code-string } // Literal.String.Other
    .sr { color: $code-string } // Literal.String.Regex
    .s1 { color: $code-string } // Literal.String.Single
    .ss { color: $code-string } // Literal.String.Symbol
    .il { color: $code-num } // Literal.Number.Integer.Long
    .p  { color: $code-operator } // Punctuation
    .o  { color: $code-operator; font-weight: bold } // Operator
    .kc { color: $code-constant; font-weight: bold } // Keyword.Constant
    .d  { color: $code-comment } // Data
    @media (prefers-color-scheme: dark) {
        .nl { color: $dm-code-label; font-weight: bold } // Name.Label
        .p  { color: $dm-code-operator } // Punctuation
        .o  { color: $dm-code-operator; font-weight: bold } // Operator
        .kc { color: $dm-code-constant; font-weight: bold } // Keyword.Constant
    }
    
    .d0 { color: $p8-black } // Data.Zero
    .d1 { color: $p8-storm; -webkit-text-stroke: 1px #C2C3C7} // Data.One
    .d2 { color: $p8-wine } // Data.Two
    .d3 { color: $p8-moss } // Data.Three
    .d4 { color: $p8-tan } // Data.Four
    .d5 { color: $p8-slate } // Data.Five
    .d6 { color: $p8-silver } // Data.Six
    .d7 { color: $p8-white } // Data.Seven
    .d8 { color: $p8-ember } // Data.Eight
    .d9 { color: $p8-orange } // Data.Nine
    .da { color: $p8-lemon } // Data.A
    .db { color: $p8-lime } // Data.B
    .dc { color: $p8-sky } // Data.C
    .dd { color: $p8-dusk } // Data.D
    .de { color: $p8-pink } // Data.E
    .df { color: $p8-peach } // Data.F

    .d, .d0, .d1, .d2, .d3, .d4, .d5, .d6, .d7, .d8, .d9, .da, .db, .dc, .dd, .de, .df {
        font-size: 12pt; font-weight: bold; line-height: 0px; margin: -10px 0; padding: 10px 0;
    }

    /* Chromium does not like light highlights in dark mode and messes
       up the rendering so only do this on Firefox and light mode */
    @supports (-moz-appearance: none) {
        .d0    { background-color: #000000; -webkit-text-stroke: 1px #C2C3C7 }
        .d1    { background-color: #1D2B53; -webkit-text-stroke: 1px #C2C3C7 }
        .d2    { background-color: #7E2553; -webkit-text-stroke: 1px #C2C3C7 }
        .d3    { background-color: #008751; -webkit-text-stroke: 1px #C2C3C7 }
        .d4    { background-color: #AB5236; -webkit-text-stroke: 1px #C2C3C7 }
        .d5    { background-color: #5F574F; -webkit-text-stroke: 1px #C2C3C7 }
        .d6    { background-color: #C2C3C7; -webkit-text-stroke: 1px #FFF1E8 }
        .d7    { background-color: #FFF1E8; -webkit-text-stroke: 1px #C2C3C7 }
        .d8    { background-color: #FF004D; -webkit-text-stroke: 1px #FFF1E8 }
        .d9    { background-color: #FFA300; -webkit-text-stroke: 1px #FFF1E8 }
        .da    { background-color: #FFEC27; -webkit-text-stroke: 1px #FFF1E8 }
        .db    { background-color: #00E436; -webkit-text-stroke: 1px #FFF1E8 }
        .dc    { background-color: #29ADFF; -webkit-text-stroke: 1px #FFF1E8 }
        .dd    { background-color: #83769C; -webkit-text-stroke: 1px #C2C3C7 }
        .de    { background-color: #FF77A8; -webkit-text-stroke: 1px #C2C3C7 }
        .df    { background-color: #FFCCAA; -webkit-text-stroke: 1px #C2C3C7 }
    }
    @media (prefers-color-scheme: light) {
        .d0    { background-color: #000000; -webkit-text-stroke: 1px #C2C3C7 }
        .d1    { background-color: #1D2B53; -webkit-text-stroke: 1px #C2C3C7 }
        .d2    { background-color: #7E2553; -webkit-text-stroke: 1px #C2C3C7 }
        .d3    { background-color: #008751; -webkit-text-stroke: 1px #C2C3C7 }
        .d4    { background-color: #AB5236; -webkit-text-stroke: 1px #C2C3C7 }
        .d5    { background-color: #5F574F; -webkit-text-stroke: 1px #C2C3C7 }
        .d6    { background-color: #C2C3C7; -webkit-text-stroke: 1px #FFF1E8 }
        .d7    { background-color: #FFF1E8; -webkit-text-stroke: 1px #C2C3C7 }
        .d8    { background-color: #FF004D; -webkit-text-stroke: 1px #FFF1E8 }
        .d9    { background-color: #FFA300; -webkit-text-stroke: 1px #FFF1E8 }
        .da    { background-color: #FFEC27; -webkit-text-stroke: 1px #FFF1E8 }
        .db    { background-color: #00E436; -webkit-text-stroke: 1px #FFF1E8 }
        .dc    { background-color: #29ADFF; -webkit-text-stroke: 1px #FFF1E8 }
        .dd    { background-color: #83769C; -webkit-text-stroke: 1px #C2C3C7 }
        .de    { background-color: #FF77A8; -webkit-text-stroke: 1px #C2C3C7 }
        .df    { background-color: #FFCCAA; -webkit-text-stroke: 1px #C2C3C7 }
    }
}
