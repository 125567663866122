header.exhibit-header {
  h1, ul.exhibit-links li {
    display: inline;
  }

  h1, h2 {
    margin-top: 0;
  }

  img.licence-badge {
    display: block;
    width: 120px;
  }
}

.credits-title {
  display: none;
}

.banner_88x31 {
  display: block;
}

.credits, .footnotes {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
  font-size: 16px;

  li p {
    text-align: left;
  }
}

.footnotes {
  margin-top: 4rem;
}

.media-container {
  height: 0px;
  padding-top: 25px;
  padding-bottom: 56.2%;
  position: relative;
  margin-bottom: 3rem;
}

.media-container iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}

.media-container video {
  width: 100%;
  height: 100%;
  position: absolute;
}