.chapter-timestamp {
  font-weight: bold;
}

.chapter-list {
  list-style-type: none;
}

.audio-chapters {
  padding: 20px;
  margin-bottom: 50px;
}



.audio-container {
  position: relative;

  audio {
    width: 100%;
  }
}

