@import "fonts-titles";
@import "fonts-general";
@import "colour-definitions";


$page-width: 36rem;
$avatar-width: 64px;
$collapse-size: $page-width * 2;
$gallery-width: 1300px;
$tile-width: 384px;

$english-font-family: "Crimson Text", serif;
$irish-font-family: "Mínchló GC", serif;
$greek-font-family: "GFS Neohellenic", sans-serif;
$monospace-font-family: "Fira Code", monospace;

.homepage{
  column-width: $page-width;
  column-gap: 1rem;

  section {
    margin: 1rem auto 2rem auto;
    max-width: $page-width;
    break-inside: avoid;
    padding: 1rem;
    background-color: $background-gradient;
    border-radius: 2rem;

    @media (prefers-color-scheme: dark) {
      border-color: $p8-dusk;
      background-color: $background-gradient-dark;
    }

    .avatar {
      float: right;
      margin-top: 2rem;
      width: $avatar-width;
    }
  }

  h2, h3, h4 {
    text-align: center;
  }
}


.jupiter {
  border-radius: 50%;
  box-shadow: $p8-orange 0px 0px 1rem;
}


body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  background: $background-colour;

  color: $text-colour;
  background: linear-gradient(135deg, $background-colour, $background-gradient 2160px), ;

  @media (prefers-color-scheme: dark) {
    color: $text-colour-dark;
    background: $p8-midnight;
    background: linear-gradient(135deg, $background-colour-dark, rgb(0,0,0,0) 4320px), url(/styles/background/background-stars.png), $background-gradient-dark;
    background-attachment: local, fixed;
  }

  del {
    color: $text-deemphasised;
    @media (prefers-color-scheme: dark) {
      color: $text-deemphasised-dark
    }
  }

  .page-wrapper {
    flex: 1 0 auto;
    padding-bottom: 180px;
    background-image: url(/styles/background/background-footer-mountains.png);
    background-repeat: repeat-x;
    background-position: left bottom;
    @media (prefers-color-scheme: dark) {
      background-image: url(/styles/background/background-footer-city.png);
    }
  }
}

::selection, ::-moz-selection {
  text-shadow: none;
  background: $p8-wine;
  color: $text-colour-dark;
  @media (prefers-color-scheme: dark) {
    background: $p8-moss;
    color: $text-colour;  
  }
}

article.single-column {
  overflow-wrap: break-word;
  max-width: $page-width;
  margin: auto;

  margin-top: 5rem;

  @media screen and (max-width: $page-width + 4rem) {
    p, h1, h2, h3, h4, h5 {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    table {
      table-layout: fixed;
      width: 100vw;
    }
  }

  .gallery img {
    padding-bottom: 2rem;
  }

  p {
    text-align: justify;
  }
}

.thumb-grid img {
  width: 100px;
}

article {
  video {
    background-color: $p8-black;
  }

  video, iframe {
    max-width: 100%;
  }

  audio {
    width: 100%;
  }

  /* styling images and image captions */
  img {
    max-width: 100%;
  }

  figure {
    margin-left: 0;
    margin-right: 0;

    img {
      display: block;
      margin: auto;
    }

    table {
      margin: auto;
    }

    figcaption {
      text-align: center;
      font-size: 0.9em;
      font-style: italic;
      em, i {
        font-style: normal;
      }
    }
  }
}

blockquote {
  color: $text-deemphasised;
  border-left: 2px solid $text-deemphasised;
  padding-left: 0.5em;
  margin: 0;
  @media (prefers-color-scheme: dark) {
    color: $text-deemphasised-dark;
    border-left-color: $text-deemphasised-dark;
  }
  cite {
    font-style: italic;
    font-weight: bold;
    em, i {
      font-style: italic;
    }
  }
}

i.translation {
  font-style: normal;
}

.translation, .ipa, abbr {
  text-decoration: underline dotted;
  position: relative;
}

@media (hover: none) {
  .ipa:hover::after, .translation:hover::after, abbr:hover::after {
    pointer-events: none;
    content: attr(title);
    position: absolute;
    bottom: calc(100% + 5px);
    transform: translate(-50%);
    left: 50%;
    white-space: nowrap;
    background-color: $background-colour;
    border: 1px solid $text-colour;
    padding: 0.5em;
    font-style: normal;
    border-radius: 0.5em;
    @media (prefers-color-scheme: dark) {
      background-color: $background-colour-dark;
      border-color: $text-colour-dark;
    }
  }
}

*:lang(en-IE), *:lang(en) {
  font-family: $english-font-family;
  code, code span, samp, samp kbd, tt {
    font-family: $monospace-font-family;
  }
}

*:lang(ga-IE), *:lang(ga) {
  font-family: $irish-font-family;
  code, code span, samp, samp kbd, tt {
    font-family: $monospace-font-family;
  }
}

*:lang(grc), *:lang(el) {
  font-family: $greek-font-family;
}

*:lang(ja) {
  font-family: "IPAexMincho", sans-serif;
}

span:lang(ga-IE), span:lang(ga), span.gaeilge {
  font-size: 90%;
}

span:lang(ja) {
  font-size: 90%;
}

.english {
  font-family: $english-font-family;
}

.gaeilge {
  font-family: $irish-font-family;
}


h1, h2, h3 {
  font-weight: normal;
  margin-bottom: 0;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-weight: bold;
  font-size: 1.5rem;
}

table {
  border-collapse: collapse;
}

th, td {
  border: 1px solid;
  padding: 0.3rem;
}

.button-zone {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  img, .badgeless {
    margin: 1px;
    image-rendering: pixelated;
    height: 31px;
    width: 88px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .button-zone {
    a:hover {
      filter: drop-shadow(0 0 15px black);
      z-index: 10;
      position: relative;

      img {
        transform: scale(2);
      }
    }
    a.badgeless:hover {
      transform: scale(2);
    }
  }
}

.badgeless {
  display: inline-block;
  width: 88px;
  height: 31px;
  background-image: url('/88x31/paper.png');
  align-content: center;
  span {
    display: inline-block;
    font-family: "Special Elite", cursive;
    font-size: 1rem;
    color: black;  
    transform: rotate(6deg);
  }
}

.post-meta {
  display: inline-block;
  font-size: 1rem;
  color: $text-deemphasised;
  @media (prefers-color-scheme: dark) {
      color: $text-deemphasised-dark;
  }
  a {
    color: $text-deemphasised !important;
    @media (prefers-color-scheme: dark) {
        color: $text-deemphasised-dark !important;
    } 
  }
  p {
    margin: 0;
  }
}

.infobox {
  display: block;
  font-weight: normal;
  border-width: 1px;
  border-radius: 8px;
  text-indent: -1.3em;
  padding: 0.5em 1em 1em 2em;
  background-color: $p8-white;
  border: 1px solid $p8-crimson;
  color: $p8-crimson;
  @media (prefers-color-scheme: dark) {
      background-color: $p8-black;
      border: 1px solid $p8-coral;
      color: $p8-coral;
  }
}

.infobox::before {
  content: 'ⓘ';
  margin-right: 5px;
}


.webrings {
  p {
    text-align: center;
    table {
      margin: auto;
      tr td {
        border: 0;
        outline: 0;
        img {
          vertical-align: middle;
        }
      }
    }
  }
}


/* move this to its own thing when I migrate this page to a blog post */

.spiral-loop-table {
  position: relative;
  left: calc(50% - 45vw);
  width: 90vw;
  font-size: 0.8em;
}

.spiral-loop-same {
  background-color: $p8-sky;
  @media (prefers-color-scheme: dark) {
      color: $p8-coral;
      background-color: transparent;
  }
}

.spiral-loop-removed {
  background-color: $p8-ember;
  @media (prefers-color-scheme: dark) {
      color: $p8-ember;
      background-color: transparent;
  }
}

.spiral-loop-added {
  background-color: $p8-lime;
  @media (prefers-color-scheme: dark) {
      color: $p8-lime;
      background-color: transparent;
  }
}

.spiral-loop-edited {
  background-color: $p8-orange;
  @media (prefers-color-scheme: dark) {
      color: $p8-orange;
      background-color: transparent;
  }
}

.spiral-loop-moved {
  background-color: $p8-pink;
  @media (prefers-color-scheme: dark) {
      color: $p8-pink;
      background-color: transparent;
  }
}

.linkz {
  p {
    text-align: center;
    line-height: 0;
  }

  h4 {
    text-align: center;
    margin-bottom: 0;
  }
}

pre.linkback {
  position: relative;
  background-color: transparent;
  font-size: 0.8rem;
  overflow: hidden;
  margin: auto;
  height: 124px;
  width: 352px;
  border: none;
  align-content: center;
}

pre.linkback::after  {
  content: "";
  image-rendering: pixelated;
  //this is broken with new section backgrounds
  background: url("/88x31/oakreef.gif");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

#celtring {
  text-align: center;
  font-size: 0.8em;
  img {
    float: left;
  }
  p {
    margin: 0;
  }
  width: unset !important;
  height: unset !important;
  background-color: inherit !important;
  border: none !important;
  color: inherit !important;
  a {
    color: $external-link-colour !important;
    @media (prefers-color-scheme: dark) {
      color: $external-link-colour-dark !important;
    }
  }
  a:hover {
    color: $link-hover-colour !important;
    @media (prefers-color-scheme: dark) {
        color: $link-hover-colour-dark !important;
    }
  }
}


.bog-listing {
  li {
    list-style: none;
    a {
      text-indent: -2rem;
      padding-left: 2rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    a:lang(ga-IE), a:lang(ga) {
      font-size: 95%;
    }
  }
}

details {
  summary {
    font-weight: bold;
  }
  p, ol, ul {
    margin-top: 0;
  }
  p {
    padding-left: 1em;
  }
}

.pixelart {
  image-rendering: pixelated
}

.emote {
  max-width: 32px;
  max-height: 32px;
  image-rendering: pixelated;
  margin: 0;
  vertical-align: middle;
}

.whiteboard-border {
  margin-top: 28px;
  border-image: url("/styles/whiteboard-border.png") 28 fill / 28px / 28px stretch;
}

.hidden-without-js {
  display: none;
}

@import "links";
@import "fancy-text";
@import "cursors";
@import "code";
@import "gallery";
@import "navbar";
@import "exhibit";
@import "exhibit-links";
@import "podcasts";
@import "episodes";
@import "printing";
@import "comments";
@import "bog";
