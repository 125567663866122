
// Pico-8 colours
$p8-black: #000000;
$p8-storm: #1d2b53;
$p8-wine: #7e2553;
$p8-moss: #008751;
$p8-tan: #ab5236;
$p8-slate: #5f574f;
$p8-silver: #c2c3c7;
$p8-white: #fff1e8;
$p8-ember: #ff004d;
$p8-orange: #ffa300;
$p8-lemon: #ffec27;
$p8-lime: #00e436;
$p8-sky: #29adff;
$p8-dusk: #83769c;
$p8-pink: #ff77a8;
$p8-peach: #ffccaa;
$p8-cocoa: #291814;
$p8-midnight: #111d35;
$p8-port: #422136;
$p8-sea: #125359;
$p8-leather: #742f29;
$p8-charcoal: #49333b;
$p8-olive: #a28879;
$p8-sand: #f3ef7d;
$p8-crimson: #be1250;
$p8-amber: #ff6c24;
$p8-tea: #a8e72e;
$p8-jade: #00b543;
$p8-denim: #065ab5;
$p8-aubergine: #754665;
$p8-salmon: #ff6e59;
$p8-coral: #ff9d81;

$background-colour: #fff1c4;
$background-gradient: #ffdfd6;
$background-colour-dark: $p8-storm;
$background-gradient-dark: $p8-midnight;

$text-colour: $p8-black;
$text-colour-dark: $p8-white;
$text-deemphasised: $p8-charcoal;
$text-deemphasised-dark: $p8-silver;

$external-link-colour: $p8-tan;
$external-link-colour-dark: #67CBA0;
$internal-link-colour: $p8-amber;
$internal-link-colour-dark: $p8-sky;
$internal-link-colour-visited: $p8-ember;
$internal-link-colour-visited-dark: $p8-pink;
$link-hover-colour: $p8-olive;
$link-hover-colour-dark: $p8-dusk;

// brand colours
$patreon-colour: #FF424D;
$twitter-colour: #1d9bf0;
$cohost-colour: #83254f;
$dm-cohost-color: #ffab5c;
$mastodon-colour: #6364FF;
$thingiverse-colour: #248bfb;
