// English
@font-face {
    font-family: "Crimson Text";
    src:
        local('Crimson Text'),
        local('CrimsonText-Regular'),
        url("/styles/fonts/en/CrimsonText-Regular.ttf");
    font-display: block;
}

@font-face {
    font-family: "Crimson Text";
    src:
        local('Crimson Text Bold'),
        local('CrimsonText-Bold'),
        url("/styles/fonts/en/CrimsonText-Bold.ttf");
    font-weight: bold;
    font-display: block;
}

@font-face {
    font-family: "Crimson Text";
    src:
        local('Crimson Text Italic'),
        local('CrimsonText-Italic'), 
        url("/styles/fonts/en/CrimsonText-Italic.ttf");
    font-style: italic;
    font-display: block;
}


// Irish
@font-face {
    font-family: "Mínchló GC";
    src:
        local('Minchlo GC'),
        local('MinchloGC-Regular'),
        url("/styles/fonts/ga/min.woff2");
    font-display: block;
}

@font-face {
    font-family: "Mínchló GC";
    src:
        local('Minchlo GC Bold'),
        local('MinchloGC-Bold'),
        url("/styles/fonts/ga/mint.woff2");
    font-weight: bold;
    font-display: block;
}

@font-face {
    font-family: "Mínchló GC";
    src:
        local('Minchlo GC Italic'),
        local('MinchloGC-Italic'),
        url("/styles/fonts/ga/mini.woff2");
    font-style: italic;
    font-display: block;
}


// Greek
@font-face {
    font-family: "GFS Neohellenic";
    src:
        local('GFS Neohellenic'),
        local('GFSNeohellenic'),
        url("/styles/fonts/el/GFSNeohellenic-Regular.ttf");
    font-display: block;
}

@font-face {
    font-family: "GFS Neohellenic";
    src:
        local('GFS Neohellenic'),
        local('GFSNeohellenic'),
        url("/styles/fonts/el/GFSNeohellenic-Bold.ttf");
    font-weight: bold;
    font-display: block;
}

@font-face {
    font-family: "GFS Neohellenic";
    src:
        local('GFS Neohellenic'),
        local('GFSNeohellenic'),
        url("/styles/fonts/el/GFSNeohellenic-Italic.ttf");
    font-style: italic;
    font-display: block;
}


// Japanese
@font-face {
    font-family: "IPAexGothic";
    src:
        local('IPAexMincho'),
        url("/styles/fonts/ja/ipaexg.ttf");
    font-display: block;
}


// Code
@font-face {
    font-family: "Fira Code";
    src:
        local('Fira Code'),
        local('FiraCode-Regular'),
        url("/styles/fonts/code/FiraCode-Regular.ttf");
    font-display: block;
}

@font-face {
    font-family: "Fira Code";
    src:
        local('Fira Code Bold'),
        local('FiraCode-Bold'), 
        url("/styles/fonts/code/FiraCode-Bold.ttf");
    font-weight: bold;
    font-display: block;
}


// Other
@font-face {
    font-family: "Cló Piocó-8";
    src: url("/styles/fonts/Cló_Piocó-8_2.0.ttf");
    font-display: block;
}

@font-face {
    font-family: "Cló Piocó-8 v1";
    src: url("/styles/fonts/Cló_Piocó-8_1.0.ttf");
    font-display: block;
}

@font-face {
    font-family: "Special Elite";
    src:
        local("Special Elite"),
        url("/styles/fonts/SpecialElite.ttf");
}
