/* override everything fancy for easy printing */
@media print {
  body {
    max-width: none !important;
    margin: none !important;
    padding: none !important;
    color: black !important;

    main {
      background-color: white !important;
      background: white !important;
      padding: 0 !important;

      article {
        iframe, video, audio {
          display: none !important;
        }
      }
    }
  }



  /* let's not waste coloured ink */
  a {
      color: black !important;
      text-decoration: underline !important;
  }
  a::after {
    content: " (" attr(href) ")" !important;
  }

  .licence-badge-link::after {
    font-size: 18px !important;
  }

  .navbar {
    display: none !important;
  }

  pre {
    background-color: white !important;
    border: none;
    code {
        background-color: white !important;
        span {
            color: black !important;
        }
    }
  }

  article p {
    text-align: left !important;
    text-justify: auto !important;
  }

  section.gallery ul li {
    flex: auto !important;
    width: 100% !important;
    height: auto !important;
    background-image: none !important;
    padding: 0px !important;
    border-radius: 0px !important;
  }


  .episodes ul a li img,
  .episodes ul a li audio{
      display: none !important;
  }

  ul.exhibit-links {
    display: block !important;
    font-size: 18px;

    li {
      $link-icon-width: 2rem;
      $link-icon-margin: 0.3rem;

      display: block !important;
      
      a::after {
        content: " " attr(href) !important;
      }

      img {
        -webkit-filter: grayscale(1) !important;
        filter: grayscale(1) !important;
        width: $link-icon-width!important;
        margin-left: $link-icon-margin !important;
        margin-right: $link-icon-margin !important;
      }

      svg {
        fill: black !important;
        width: $link-icon-width !important;
        margin-left: $link-icon-margin !important;
        margin-right: $link-icon-margin !important;
      }

      .thingiverse-icon g circle {
        fill: black !important;
      }

      a svg.apple-podcast-icon{
        .cls-1{fill:url(#linear-gradient-print);}
      }

      a svg.google-podcasts-icon{
          .cls-1,.cls-2,.cls-3,.cls-4,.cls-5{stroke:black !important;}
      }

      a svg.subscribe-on-android-icon{
          .st2{fill:black !important;}
      }

      #git-repo-box {
        visibility: visible !important;
        position: relative !important;
        border: 0 !important;
        border-radius: 0 !important;
        padding: 0 !important;
        display: inline !important;
        background-color: white !important;

        h4 {
          display: none !important;
        }

        p {
          display: inline !important;
        }

        p::after {
          content: '\a' !important;
          white-space: pre !important;
        }

        :not(p:first-of-type){
          margin-left: $link-icon-width + $link-icon-margin * 2 !important;
        }
      }
    }
  }

  .linkz p {
    line-height: normal;
  }

  .button-zone {
    flex-direction: column;
  }
}
