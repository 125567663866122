
section.gallery {
    max-width: $gallery-width;
    margin: auto;

    ul {
        display:flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 0;

        li {
            flex: 0 1 $tile-width;
            width: $tile-width;
            height: $tile-width;
            margin: 10px;
            padding: 30px;
            box-sizing: border-box;
            background-position: center;
            background-size: cover;
            image-rendering: pixelated;
            background-repeat: no-repeat;
            overflow: hidden;
            border-radius: 32px;
            position: relative;
            break-inside: avoid;
        }
    }
}
