// Cohost
@font-face {
    font-family: "Atkinson Hyperlegible";
    src:
        local('Atkinson Hyperlegible'),
        url("/styles/fonts/mini/Atkinson-Hyperlegible-Cohost.ttf");
        font-display: block;
}


// oak reef zone
@font-face {
    font-family: "Rocodecoco";
    src:
        local('Rocodecoco'),
        local('Gaslight Regular'),
        local('Sonic Roco'),
        local('SonicRoco'),
        local('Sonic2TitleCardFont'),
        url("/styles/fonts/mini/RocodecocOak-Reef-Zone.otf");
}


// contact
@font-face {
    font-family: "Sonic 1 Act Text";
    src:
        local('Sonic1ActTextNowWithSymbols!'),
        url("/styles/fonts/mini/Sonic-1-contACT.ttf");
}


// web rings
@font-face {
    font-family: "Sonic HUD Font";
    src:
        local('Sonic HUD Font'),
        local('SonicHUDFont'),
        url("/styles/fonts/mini/sonic-1-web-RINGS.ttf");
}


// Collect my pages / the ring*
@font-face {
    font-family: "SOME RINGS";
    src:
        local('SOME RINGS'),
        local('Some Rings'),
        url("/styles/fonts/mini/CollectSomeRings.ttf");
}


// Gallery / Dánlann
@font-face {
    font-family: "Tate";
    src:
        local('Tate'),
        local('Tate-Regular'),
        url("/styles/fonts/mini/Tate-Gallery.ttf");
}


// Transy / Art
@font-face {
    font-family: "Hobo";
    src:
        local('Hobo'),
        url("/styles/fonts/mini/Hobo-Transy.ttf");
}


// ⏴⏶⏷⏵ＸＺ␫
@font-face {
    font-family: "PromptFont";
    src:
        local("PromptFont"),
        local("Prompt Font"),
        url("/styles/fonts/mini/Prompt-Font-Pico8.ttf");
}


// Link to me
@font-face {
    font-family: "Return of Ganon";
    src:
        local("Return of Ganon"),
        local("ReturnofGanon"),
        url("/styles/fonts/mini/Return-of-Ganon-Link-to-Me.ttf");
}


// pod person
@font-face {
    font-family: "Metroid II: Return of Samus (Colour)";
    src:
        local("Metroid II: Return of Samus (Colour)"),
        local("Metroid II: Return of Samus"),
        local("Metroid II"),
        url("/styles/fonts/mini/metroid-ii-pod-person-colour.svg.tff");
}
@font-face {
    font-family: "Metroid II: Return of Samus";
    src:
        local("Metroid II: Return of Samus"),
        local("Metroid II"),
        url("/styles/fonts/mini/metroid-ii-pod-person.tff");
}


// Cookies & Tracking
@font-face {
    font-family: "Kavoon";
    src:
        local("Kavoon"),
        url("/styles/fonts/mini/Kavoon-cookies.ttf");
}


// Button Bin
@font-face {
    font-family: "Comic Sans MS";
    src:
        local("Comic Sans MS"),
        local("Comic Sans"),
        local("Comic Neue")
        url("/styles/fonts/mini/Comic-Sans-Other-cool-people.ttf");
}


// other cool people
@font-face {
    font-family: "Impacted Label Reversed";
    src:
        local("Impacted Label Reversed"),
        url("/styles/fonts/mini/Impact-Label-Reversed-button-bin.ttf");
}


// snolf
@font-face {
    font-family: "SegaSonic";
    src:
        local('NiseSegaSonic!'),
        url("/styles/fonts/NiseSegaSonic.TTF");
}

// THE GRUDGE
@font-face {
    font-family: "Impacted";
    src:
        local("Impacted"),
        url("/styles/fonts/impacted.ttf");
}
