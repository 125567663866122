
/* homepage headers */
.sonic-2 {
    font-family: "Rocodecoco";
    text-transform: lowercase;
    color: $p8-wine;
    text-shadow: 5px 5px $p8-silver;
    @media (prefers-color-scheme: dark) {
        color: white;
        text-shadow: 5px 5px black;
    }
}

.sonic-1 {
    font-family: "Sonic 1 Act Text";
    text-transform: lowercase;
    color: #eeaa88;
    text-shadow: 3px 3px black;
    font-size: 2rem;
    margin-top: -1rem;
}

.rings {
    font-family: "Sonic HUD Font";
    color: #fcfc00;
    text-shadow: 0px 3px black, 3px 0px black, 3px 3px black;
    text-transform: lowercase;
    font-size: 2em;
}

.link-to-the-past {
    font-family: "Return of Ganon";
    color: #f8f8f8;
    text-shadow:
        2px 2px #000070,
        -2px -2px #000070,
        2px -2px #000070,
        -2px 2px #000070,
        0px 2px #000070,
        0px -2px #000070,
        -2px 0px #000070,
        2px 0px #000070;
}


h2.metroid {
    font-size: 1.8rem;
}

.metroid {
    text-transform: lowercase;
    font-family: "Metroid II: Return of Samus";
    @media(prefers-color-scheme: dark) {
        font-family: "Metroid II: Return of Samus (Colour)";
    }
}

.grudge {
    font-family: Impacted, Impact, sans-serif;
    color: $p8-crimson;
    font-style: normal;
    text-transform: uppercase;
    @media (prefers-color-scheme: dark) {
        color: $p8-ember;
    }
}

.cookie {
    font-family: "Kavoon";
    color: black;
    text-shadow: grey 5px 8px 8px;
    @media (prefers-color-scheme: dark) {
        color: white;
        text-shadow: black 5px 8px 8px;
    }
}

.slender {
    font-family:'SOME RINGS';
    color: black;   
    text-shadow: -20px 10px 1px $p8-ember, -10px -14px 1px $p8-ember, 9px -8px 1px $p8-ember, 9px 8px 1px $p8-ember;
    @media (prefers-color-scheme: dark) {
        color: white;
        text-shadow: 3px 3px black, -10px -14px 1px black, 9px -8px 1px black, 9px 8px 1px black;
    }
}

.the-ring {
    font-family:'SOME RINGS';
    text-transform: lowercase;
    text-shadow: 1px 1px 2px $p8-silver;
    @media (prefers-color-scheme: dark) {
        text-shadow: 1px 1px 2px black;
    }
}

.the-ring::after {
    content: "*";
    position: relative;
    right: 2em;
    top: 0.1em;
    opacity: 50%;
    display: inline-block;
    width: 0;
    height: 0;
}


.label {
    font-family: "Impacted Label Reversed";
    text-transform: lowercase;
    color: $p8-white;
    background-color: $p8-black;
    rotate: -2deg;
    text-shadow: 1px 0px $p8-slate, -1px 0px $p8-slate;
    box-shadow:
        0 1px 0 rgba(255, 255, 255, 0.1),
        0 1px 0 rgba(255, 255, 255, 0.1) inset,
        0 0.5em 6px rgba(255, 255, 255, .1) inset;
}

.tate, .tate a {
    font-family: "Tate";
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $p8-ember;
    text-shadow: 0px 0px 6px $p8-pink;     
    @media (prefers-color-scheme: dark) {
        -webkit-text-stroke-color: $p8-pink;
        text-shadow: 0px 0px 6px $p8-ember; 
    }
}

.sa2-comic-sans {
    font-family: "Comic Sans MS", "Comic Sans", "Comic Mono", "Chalkboard SE", "Comic Neue", sans-serif;
    color: black;
    text-shadow: 4px 4px $p8-white;
    @media (prefers-color-scheme: dark) {
        color: $p8-white;
        text-shadow: 4px 4px black;
    }
}



/*homepage special text styling*/
.woman {
    color: $p8-crimson;
    text-shadow: $p8-ember 0px 0px 3px;
    font-style: italic;
    @media (prefers-color-scheme: dark) {
        color: $p8-lemon;
        text-shadow: $p8-orange 0px 0px 3px;    
    }
}


.trans-blue {
    font-weight: bold;
    background-color: $p8-sky;
    @media (prefers-color-scheme: dark) {
        color: $p8-sky;
        background-color: transparent;
    }
}

.trans-pink {
    font-weight: bold;
    background-color: $p8-pink;
    @media (prefers-color-scheme: dark) {
        color: $p8-pink;
        background-color: transparent;
    }
}

.trans-white {
    font-weight: bold;
    background-color: $p8-white;
    @media (prefers-color-scheme: dark) {
        color: $p8-white;
        background-color: transparent;
    }
}


/* special page styling */
.desert-chrome {
    font-family: "SegaSonic" !important;
    font-size: 4.5em;
    font-weight: 800;
    display: inline-block;
    text-transform: lowercase;
    white-space: nowrap;
    position: relative;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDBmZiIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIi8+PHN0b3Agb2Zmc2V0PSI1MCUiIHN0b3AtY29sb3I9IiM1NTQwMGMiLz48c3RvcCBvZmZzZXQ9IjY1JSIgc3RvcC1jb2xvcj0iI2RhYTUyMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #0000ff), color-stop(50%, #ffffff), color-stop(50%, #55400c), color-stop(65%, #daa520), color-stop(100%, #ffffff));
    background: -moz-linear-gradient(top, #0000ff, #ffffff 50%, #55400c 50%, #daa520 65%, #ffffff);
    background: -webkit-linear-gradient(top, #0000ff, #ffffff 50%, #55400c 50%, #daa520 65%, #ffffff);
    background: linear-gradient(to bottom, #0000ff, #ffffff 50%, #55400c 50%, #daa520 65%, #ffffff);
      background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    @media (prefers-color-scheme: dark) {
        -webkit-text-stroke-color: white;
    }
}

.piocó {
    font-family: "Cló Piocó-8", monospace !important;
}

.piocó-v1 {
    font-family: "Cló Piocó-8 v1", monospace !important;
}

.piocó-ogham {
    font-family: "Cló Piocó-8" !important;
    letter-spacing: -1px;
    margin-left: auto;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    @supports (writing-mode: sideways-lr) {
        writing-mode: sideways-lr;
        transform: rotate(0);
    }
}

.hobo {
    font-family: "Hobo" !important;
}

.mono {
    font-family: $monospace-font-family;
}

.gaelic {
    font-family: $irish-font-family;
    font-feature-settings: 'ss01';
}

.bog, .bog a, .bog a:visited {
    font-family: $irish-font-family;
    color: $p8-amber;
    text-shadow: 0.05em 0.05em black;
    text-transform: lowercase;
    @media (prefers-color-scheme: dark) {
        color: $p8-lemon;
    }
}

kbd {
    border-radius: 3px;
    display: inline-block;
    font-size: 0.7em;
    font-weight: 700;
    font-style: normal;
    line-height: 1;
    padding: 2px 4px;
    white-space: nowrap;
    color: #333;
    background-color: #eee;
    border: 1px solid #b4b4b4;
    box-shadow:
        0 1px 1px rgba(0, 0, 0, 0.2),
        0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
    @media (prefers-color-scheme: dark) {
        color: #eee;
        background-color: #333;
        border: 1px solid #4b4b4b;
        box-shadow:
            0 1px 1px rgba(255, 255, 255, 0.7),
            0 2px 0 0 rgba(0, 0, 0, 0.2) inset;
    }
}

samp kbd {
    background-color: inherit;
    border: none;
    box-shadow: none;
}

kbd.key {
    background-color: inherit;
    border: none;
    box-shadow: none;
    font-family: PromptFont, monospace, sans-serif;
    padding: 0;
    font-size: 2em;
    font-weight: normal;
    font-style: normal;
}
