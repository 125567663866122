@use 'sass:math';

article.bog-post {
	margin-top: 5rem;
	margin-bottom: 3rem;
	header {
		@media screen and (max-width: $collapse-size) {
			display: flex;
		}
		.avatar-container {
			z-index: 10;
			width: $avatar-width;
			@media screen and (max-width: $collapse-size) {
				height: $avatar-width;
				margin-right: 1rem;
				align-self: center;
			}
			@media screen and (min-width: $collapse-size) {
				position: relative;
				right: calc($avatar-width + 1.5rem);
				top: 0.5rem;
				height: 0;
			}
			.outside-avatar {
				border-radius: 10%;
				min-width: 100%;
			}
			.syndication-source {
				display: block;
				width: math.div($avatar-width,2);
				height: math.div($avatar-width,2);
				position: relative;
				top: calc( ($avatar-width + 1rem) / -2);
				left: math.div($avatar-width,2);
				img {
					border-radius: 10%;
				}
			}
		}
		h1 {
			font-size: 2rem;
			margin-top: 0;
		}
		h2 {
			font-size: 1.8rem;
		}
		h3 {
			font-size: 1.6rem;
		}
		h4 {
			font-weight: bold;
			font-size: 1.4rem;
		}
	}

	.post-body {
		overflow: auto;
		img.poster {
			float: right;
			margin-left: 1rem;
			margin-bottom: 1rem;
			margin-top: 1rem;
			max-width: 33%;
		}
	}

	hr {
		margin: 3rem 1rem;
		border: none;
		border-top-width: 0.3rem;
		border-top-style: dotted;
		border-top-color: $text-deemphasised;
		@media (prefers-color-scheme: dark) {
			border-top-color: $text-deemphasised-dark;
		}
	}
}


.poll {

	li {
		list-style-type: none;
	}

	.poll_bar_full {
		border-radius: 4px;
		display: block;
		height: 5px;
		width: 100%;
		background: $text-deemphasised;
		@media (prefers-color-scheme: dark) {
			background: $text-deemphasised-dark;
		}
	}
	
	.poll_bar {
		border-radius: 4px;
		display: block;
		height: 5px;
		min-width: 1%;
		background: $internal-link-colour;
		@media (prefers-color-scheme: dark) {
			background: $internal-link-colour-dark;
		}
	}
}

@media screen and (max-width: $collapse-size) {
	.bog-page {
		max-width: $page-width;
		margin:auto
	}
}

.bog-header, .bog-body {
	max-width: $page-width;
	margin:auto
}

.bog-body hr {
	border-width: 2px;
}

.repost {
	border: solid 1px;
	background-color: $p8-white;
	border: 1px solid $text-colour;
	padding: 1rem;
	border-radius: 1rem;
	@media (prefers-color-scheme: dark) {
		background-color: #353535;
		border-color: $text-colour-dark;
	}
}
