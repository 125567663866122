header.exhibit-header {
  /* links section */
  ul.exhibit-links {
    display: inline-block;
    padding-left: 0;
    margin-left: 0;
    list-style: none;
  }

  ul.exhibit-links img { &:hover {
    filter: sepia(1) hue-rotate(328deg);
    -webkit-filter: sepia(1) hue-rotate(328deg);
    @media (prefers-color-scheme: dark){
        filter: sepia(1) hue-rotate(250deg);
        -webkit-filter: sepia(1) hue-rotate(250deg);
    }
  }}

  .download-icon {
    display: inline-block;
    fill: currentColor;
    fill-rule:evenodd;
    vertical-align: middle;
    margin-left: 8px;
    max-width: 2.5rem;
    max-height: 2.5rem;
  }

  svg.download-icon {
    width: 2.5rem;
  }

  a.download-link{
    text-decoration: none !important;
  }

  /* git repos */
  .git-trigger {
    display: none;
  }

  #git-repo-button {
    position: relative;
  }

  #git-repo-icon {
    color: $internal-link-colour;
    @media (prefers-color-scheme: dark) {
        color: $internal-link-colour-dark;
    }
    &:hover {
        color: $link-hover-colour !important;
        @media (prefers-color-scheme: dark){
            color: $link-hover-colour-dark !important;
        }
    }
  }

  #git-repo-box {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 9;
    font-size: 10pt;
    padding: 16px 16px 4px 16px;
    white-space : nowrap;
    background-color: $background-colour;
    border: 1px solid $text-colour;
    border-radius: 16px;
    @media screen and (max-width: 600px) {
        white-space : normal;
    }
    @media (prefers-color-scheme: dark) {
        background-color: $background-colour-dark;
        border: 1px solid $text-colour-dark;
  }
  }

  #git-trigger ~ #git-repo-box {
    display: none;
    h4 {
      margin-top: 0;
    }
  }

  #git-trigger:checked ~ #git-repo-box {
    display: block;
  }

  #git-trigger:checked + #git-trigger-label > #git-repo-icon {
    color: $internal-link-colour-visited;
    @media (prefers-color-scheme: dark) {
        color: $internal-link-colour-visited-dark;
    }
  }


  /* specific links */
  // #pico-8-thumb {
  //   max-width: 2.5rem;
  //   max-width: 2.5rem;
  //   image-rendering: pixelated;
  //   margin-left: 12px;
  // }



  .rss-icon {
    color: $p8-orange;
    &:hover {
        color: $link-hover-colour;
        @media (prefers-color-scheme: dark){
            color: $link-hover-colour-dark;
        }
    }
  }

  .patreon-icon {
    color: $patreon-colour;
    &:hover {
        color: $link-hover-colour;
        @media (prefers-color-scheme: dark){
            color: $link-hover-colour-dark;
        }
    }
  }

  .tumblr-icon {
    color: $p8-slate;
    @media (prefers-color-scheme: dark) {
        color: white;
    }

    &:hover {
        color: $link-hover-colour;
        @media (prefers-color-scheme: dark){
            color: $link-hover-colour-dark;
        }
    }
  }

  .bluesky-icon:hover path {
    fill: $link-hover-colour;
    @media (prefers-color-scheme: dark){
        fill: $link-hover-colour-dark;
    }
  }

  .thingiverse-icon g circle {
    fill: $thingiverse-colour;
  }

  .thingiverse-icon:hover g circle {
    fill: $link-hover-colour;
    @media (prefers-color-scheme: dark){
        fill: $link-hover-colour-dark;
    }
  }

  .cohost-icon {
    color: $cohost-colour;
    @media (prefers-color-scheme: dark){
        color: $dm-cohost-color;
    }

    &:hover {
        color: $link-hover-colour;
        @media (prefers-color-scheme: dark){
            color: $link-hover-colour-dark;
        }
    }
  }

  .mastodon-icon {
    color: $mastodon-colour;
    &:hover {
        color: $link-hover-colour;
        @media (prefers-color-scheme: dark){
            color: $link-hover-colour-dark;
        }
    }
  }

  .itch-icon {
    color: #ff2349;
    &:hover {
        color: $link-hover-colour;
        @media (prefers-color-scheme: dark){
            color: $link-hover-colour-dark;
        }
    }
  }

  .spotify-icon {
    color: #1ed760;
    &:hover {
        color: $link-hover-colour;
        @media (prefers-color-scheme: dark){
            color: $link-hover-colour-dark;
        }
    }
  }

  .internet-archive-icon {
    color: black;
    @media (prefers-color-scheme: dark){
        color: white;
    }
    &:hover {
        color: $link-hover-colour;
        @media (prefers-color-scheme: dark){
            color: $link-hover-colour-dark;
        }
    }
  }

  a svg.apple-podcast-icon{
    .cls-1{fill:url(#linear-gradient);}.cls-2{fill:#fff;}
    text-decoration: none !important;
  }

  a:hover svg.apple-podcast-icon{
    .cls-1{fill:url(#linear-gradient-hover-light);}
    @media (prefers-color-scheme: dark) {
        .cls-1{fill:url(#linear-gradient-hover);}
    }
  }

  a svg.google-podcasts-icon{
    .cls-1{stroke:#0066d9;}.cls-1,.cls-2,.cls-3,.cls-4,.cls-5{stroke-linecap:round;stroke-width:48.5px;}.cls-2{stroke:#4285f4;}.cls-3{stroke:#ea4335;}.cls-4{stroke:#34a853;}.cls-5{stroke:#fab908;}
    text-decoration: none !important;
  }

  a:hover svg.google-podcasts-icon{
    .cls-1{stroke:#743725;}.cls-1,.cls-2,.cls-3,.cls-4,.cls-5{stroke-linecap:round;stroke-width:48.5px;}.cls-2{stroke:#8b422c;}.cls-3{stroke:#a75035;}.cls-4{stroke:#b8583a ;}.cls-5{stroke:#d58f79;}
    @media (prefers-color-scheme: dark) {
        .cls-1{stroke:#5e5373;}.cls-1,.cls-2,.cls-3,.cls-4,.cls-5{stroke-linecap:round;stroke-width:48.5px;}.cls-2{stroke:#80739a;}.cls-3{stroke:#6d6085;}.cls-4{stroke:#8c80a3 ;}.cls-5{stroke:#bfb8cc;}
    }
  }

  a svg.subscribe-on-android-icon{
    .st0{fill:#202124;}
    .st2{fill:#34A853;}
  }

  a:hover svg.subscribe-on-android-icon{
    .st2{
        fill:$link-hover-colour;
        @media (prefers-color-scheme: dark){
            fill: $link-hover-colour-dark;
        }
    }
  }
}
