.comentario-root {
  padding: 3rem;
  max-width: 36rem;
  margin: auto;
  @media screen and (max-width: 39rem) {
      padding: 1rem 0 1rem 0;
      margin: 0;
      width: 100%;
  }

  .comentario-add-comment-host {
    background-color: unset;
  }
}
