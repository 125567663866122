
a {
    text-decoration: none;
    color: $external-link-colour;
    @media (prefers-color-scheme: dark) {
        color: $external-link-colour-dark;
    }
}

a:visited {
    color: $p8-leather;
    @media (prefers-color-scheme: dark) {
        color: #1BBFBC;
    }
}

a:is([href^="/"],[href^="#"],[href^="https://oakreef.ie/"],[starttime]) {
    color: $internal-link-colour;
    @media (prefers-color-scheme: dark) {
        color: $internal-link-colour-dark;
    }
}

a:hover {
    text-decoration: underline;
    color: $link-hover-colour;
    @media (prefers-color-scheme: dark) {
        color: $link-hover-colour-dark;
    }
}

a:is([href^="/"],[href^="#"],[href^="https://oakreef.ie/"],[starttime]):visited {
    color: $internal-link-colour-visited;
    @media (prefers-color-scheme: dark) {
        color: $internal-link-colour-visited-dark;
    }
}

a[href^="/"]:visited:hover {
    color: $link-hover-colour;
    @media (prefers-color-scheme: dark) {
        color: $link-hover-colour-dark;
    }
}


/* decorate links to special pages */
a[href="/transy"] {
    font-family: "Hobo", monospace;
}

a[href="/cohost"] {
    font-family: "Atkinson Hyperlegible", ui-sans-serif, system-ui, sans-serif;
}

a[href="/cohost"]:not(:has(> svg,img))::after {
    content: url("/icons/eggbug.svg");
    width: 1em;
    display: inline-block;
}

a[href="/snolf"]:not(:has(> svg,img))::after {
    content: url("/icons/snolf.png");
    display: inline-block;
}

a[href="/easóg"]:not(:has(> svg,img))::after {
    content: url("/icons/easóg.svg");
    width: 1em;
    display: inline-block;
    -webkit-filter: drop-shadow(0.5px 0.5px 0 black) 
            drop-shadow(-0.5px 0.5px 0 black)
            drop-shadow(0.5px -0.5px 0 black)
            drop-shadow(-0.5px -0.5px 0 black);
    filter: drop-shadow(0.5px 0.5px 0 black) 
            drop-shadow(-0.5px 0.5px 0 black)
            drop-shadow(0.5px -0.5px 0 black)
            drop-shadow(-0.5px -0.5px 0 black);
}

a[href$=".atom"][href^="/"]:not(:has(> svg,img))::after {
    content: "";
    background: url("/icons/rss.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-left: 0.1em;
    padding-right: 0.6em;
}

/* decorate special external links */
a[href="https://coossification.tumblr.com/"]:not(:has(> svg,img))::after {
    content: "";
    background: url("/icons/tumblr.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-left: 0em;
    padding-right: 0.8em;
}

a[href="https://bsky.app/profile/soilseacht.bsky.social"]:not(:has(> svg,img))::after {
    content: "🦋";
    background: #0085ff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

a[href="https://cathode.church/@soilseacht"]:not(:has(> svg,img))::after {
    content: "🐘";
    background: #db4ac8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

a[href="https://letterboxd.com/oakreef/"]:not(:has(> svg,img))::after {
    content: "📽️";
}

a[href="https://www.serializd.com/user/soilseacht/profile"]:not(:has(> svg,img))::after {
    content: "📺";
}

a[href="https://www.backloggd.com/u/Caoimhe/"]:not(:has(> svg,img))::after {
    content: "🎮";
}

a[href="https://www.goodreads.com/user/show/182534788-caoimhe"]:not(:has(> svg,img))::after {
    content: "📖";
}

a[href="https://throne.com/soilseacht"]:not(:has(> svg,img))::after {
    content: "🎁";
}

a[href="mailto:caoimhe@oakreef.ie"]:not(:has(> svg,img))::after {
    content: url("/icons/envelope.svg");
    width: 1em;
    display: inline-block;
    margin-left: 3px;
}

a[href^="https://en.wikipedia.org/"]:not(:has(> svg,img))::after {
    content: url("/icons/wikipedia.svg");
    width: 1em;
    display: inline-block;
    margin-left: 3px;
}

a[href^="https://commons.wikimedia.org/"]:not(:has(> svg,img))::after {
    content: url("/icons/commons.svg");
    width: 1em;
    display: inline-block;
    margin-left: 3px;
}
