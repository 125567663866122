body {
    cursor: url("/cursors/default.gif"), default;
    @media (prefers-color-scheme: dark){
        cursor: url("/cursors/default.dark.gif"), default;
    }
}

*:is(p, h1, h2, h3){
    cursor: url("/cursors/text.gif") 3 7, text;
    @media (prefers-color-scheme: dark){
        cursor: url("/cursors/text.dark.gif") 3 7, text;
    }
}

.piocó-ogham p{
    cursor: url("/cursors/vtext.gif") 7 3, vertical-text;
    @media (prefers-color-scheme: dark){
        cursor: url("/cursors/vtext.dark.gif") 7 3, vertical-text;
    }
}

a {
    cursor: url("/cursors/ext.gif") 5 0, pointer;
    @media (prefers-color-scheme: dark){
        cursor: url("/cursors/ext.dark.gif") 5 0, pointer;
    }
}

a:is([href^="/"],[href^="https://oakreef.ie/"],[href="https://oakreef.ie"]),.episodes,.episodes a *:is(p, h1, h2, h3) {
    cursor: url("/cursors/pointer.gif") 5 0, pointer;
    @media (prefers-color-scheme: dark){
        cursor: url("/cursors/pointer.dark.gif") 5 0, pointer;
    }
}

a[href^="#"] {
    cursor: url("/cursors/book.gif") 5 0, pointer;
    @media (prefers-color-scheme: dark){
        cursor: url("/cursors/book.dark.png") 5 0, pointer;
    }
}

a:is([href$=".zip"],[href$=".pdf"],[href$=".zip"],[href$=".ttf"],[href$=".stl"],[href$=".svg"],[href$=".png"],[href$=".exe"],[href$=".wad"],[href$=".epub"],[href$=".lua"])[href^="/"] {
    cursor: url("/cursors/down.gif") 5 0, pointer;
    @media (prefers-color-scheme: dark){
        cursor: url("/cursors/down.dark.gif") 5 0, pointer !important;
    }
}

a[href$=".atom"][href^="/"]{
    cursor: url("/cursors/rss.gif") 5 0, pointer;
    @media (prefers-color-scheme: dark){
        cursor: url("/cursors/rss.dark.gif") 5 0, pointer;
    }
}

a:not([href^="http"]):not([href^="/"]):not([href^="#"]):not([starttime]) {
    cursor: url("/cursors/send.gif") 5 0, pointer;
    @media (prefers-color-scheme: dark){
        cursor: url("/cursors/send.dark.gif") 5 0, pointer;
    }
}

a:is([href="https://oakreef.ie/"],[href="https://oakreef.ie"],[href="/"]) {
    cursor: url("/cursors/home.gif") 5 0, pointer;
    @media (prefers-color-scheme: dark){
        cursor: url("/cursors/home.dark.gif") 5 0, pointer;
    }
}

a[starttime] {
    cursor: url("/cursors/play.gif") 5 0, pointer;
    @media (prefers-color-scheme: dark){
        cursor: url("/cursors/play.dark.gif") 5 0, pointer;
    }
}

a.translink.gaeilge {
    cursor: url("/cursors/ga.gif") 5 0, pointer;
    @media (prefers-color-scheme: dark){
        cursor: url("/cursors/ga.dark.gif") 5 0, pointer;
    }
}

a.translink.english {
    cursor: url("/cursors/en.gif") 5 0, pointer;
    @media (prefers-color-scheme: dark){
        cursor: url("/cursors/en.dark.gif") 5 0, pointer;
    }
}

#git-repo-icon  {
    cursor: url("/cursors/context.gif") 5 0, context-menu;
    @media (prefers-color-scheme: dark){
        cursor: url("/cursors/context.dark.gif") 5 0, context-menu;
    }
}
