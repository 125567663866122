.episodes {

    margin-left: 30px;
    margin-right: 30px;


    section:not(:last-child) {
        border-bottom: 1px solid $text-colour;
        margin-bottom: 20px;
        padding-bottom: 20px;
        @media (prefers-color-scheme: dark) {
            border-bottom: 1px solid $text-colour-dark;
        }
    }

    p {
        text-align: justify;
    }

    a, a:link, a:visited, a:visited:hover, a:hover, a:active {
        text-decoration: none;
        color: $text-colour;
        @media (prefers-color-scheme: dark) {
            color: $text-colour-dark;
        }
    }

    h3 {
        margin: 0;
    }

    img {
        width: 120px;
        float: right;
        margin-left: 10px;
        margin-bottom: 5px;
    }

    audio {
        width: 100%;
    }
}

