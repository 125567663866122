@use 'sass:math';

.nav-back ul {
  list-style-type: none;
}

.nav-prev a, .nav-next a {
  display: flex;
  max-width: 25rem;
}

.nav-next {
  display: flex;
  justify-content: right;
}


.translation-icon {
  fill: $text-colour;
  @media (prefers-color-scheme: dark) {
    fill: $text-colour-dark;
  }
}

.nav-back {
  li:nth-child(2) {
    padding-left: 1rem;
  }
  li:nth-child(3) {
    padding-left: 2rem;
  }
  li:nth-child(4) {
    padding-left: 3rem;
  }
}

.navbar {
  width: $page-width;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  
  display: flex;
  flex-wrap: wrap;

  >div {
    width: 50%;
    align-content: center;
  }

  .nav-back ul {
    padding: 0;
  }
  
  .nav-lang, .nav-next {
    text-align: right;
  }
}

@media screen and (max-width: $page-width) {
  .nav-back, .nav-prev {
    a {
      margin-left: 1rem;
    }
  }
  .nav-next, .nav-lang {
    a {
      margin-right: 1rem;
    }
  }
}

@media screen and (min-width: $collapse-size) {
  
  .navbar:not(.wide) {
    width: unset;
    max-width: unset;
    margin-left: unset;
    margin-right: unset;
    display: unset;
    flex-wrap: unset;

    .nav {
      position: fixed;
      margin: 0;
      max-width: calc((100vw - $page-width - 10rem)/2);
    }

    .nav-back {
      padding-left: 3rem;
      top: 10%;
    }

    .nav-lang {
      position: fixed;
      padding-right: 3rem;
      top: 5rem;
      right: 0;
    }

    .nav-prev {
      position: fixed;
      padding-left: 3rem;
      top: 50%;
    }

    .nav-next {
      position: fixed;
      padding-right: 3rem;
      top: 50%;
      right: 0;
      text-align: right;
    }
  }
}

